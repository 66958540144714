    <template>
        <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="用户ID" prop="id">
                <el-input v-model="form.id" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="广告区间" prop="time1">
                <el-col :span="11">
                <el-date-picker
                v-model="form.time1"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="广告起始时间"
                end-placeholder="广告结束时间">
                </el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item label="药品区间" prop="time2">
                <el-col :span="11">
                <el-date-picker
                v-model="form.time2"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="药品起始时间"
                end-placeholder="药品结束时间">
                </el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="downSta">下载</el-button>
            </el-form-item>
            </el-form>
        </div>
    </template>
    
    <script>
    export default {
        name: 'BackstageIndex',
    
        data() {
            return {
                form: {
                    id: '',
                    time1: [],
                    time2:[]
                },
                rules: {
                    time1: [
                        {required: true, message: '请选择日期', trigger: 'change' }
                    ],
                    time2: [
                        {required: true, message: '请选择时间', trigger: 'change' }
                    ],
                    id:[{required: true, message: '请输入活动名称', trigger: 'blur' }],
                }
            };
        },
    
        mounted() {
            
        },
    
        methods: {
                downSta() {
                    console.log(this.$refs.form,'1111111111');
                    this.$refs.form.validate((valid) => {
                        console.log(this.form.time1)
                            if (valid) {
                            window.location.href =
                                'https://yujian02.xyz/screen/jiesuan' +
                                "?uid=" +
                                        this.form.id +
                                "&adsstart=" +
                                        this.form.time1[0] +
                                "&adsend=" +
                                        this.form.time1[1] +
                                        "&orderstart=" +
                                        this.form.time2[0] +
                                "&orderend=" +
                                        this.form.time2[1] 
                                        }
                                    })
                
                        
            }
        },
    };
    </script>
    
    <style lang="scss" scoped>
    
    </style>